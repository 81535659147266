
  a.social {
    margin: 0 1rem;
    transition: transform 250ms;
    display: inline-block;
  }

  .linkedinSocial:hover {
    color: #000000 !important;
  }

  .facebookSocial:hover {
    color: #000000 !important;

  }

  .githubSocial:hover {
    color: #000000 !important;

  }

  .mailSocialBottom:hover {
    color: #000000 !important;

  }

  /* For wide computer screen*/
@media only screen and (min-width: 770px) {

  .social-container {
    padding: 25px 50px;
  }

  .linkedinSocial {
    color: #eeeae1;
    margin: 0 1rem;
    font-size: 50px;
  }
  
  .facebookSocial {
    color: #eeeae1;
    margin: 0 1rem; 
    font-size: 50px;   
  }
  
  .githubSocial {
    color: #eeeae1;
    margin: 0 1rem;
    font-size: 50px;

  }

  .mailSocialBottom {
    color: #eeeae1;
    margin: 0 1rem;
    transition: transform 250ms;
    display: inline-block;
    font-size: 50px;
  }
}

  /* For mobile screen*/
  @media only screen and (max-width: 770px) {

    .social-container {
      padding: 0px 50px 50px 50px;
    }
    .linkedinSocial {
      color: #eeeae1;
      margin: 0 1rem;
      font-size: 40px;
    }
    
    .facebookSocial {
      color: #eeeae1;
      margin: 0 1rem; 
      font-size: 40px;   
    }
    
    .githubSocial {
      color: #eeeae1;
      margin: 0 1rem;
      font-size: 40px;
  
    }
  
    .mailSocialBottom {
      color: #eeeae1;
      margin: 0 1rem;
      transition: transform 250ms;
      display: inline-block;
      font-size: 40px;

    }
  }

  
  
  