
.social-container {
    padding: 25px 50px;
  }

  a.social {
    margin: 0 1rem;
    transition: transform 250ms;
    display: inline-block;
  }

  a.social:hover {
    transform: translateY(-2px);
  }

  .mailSocial:hover {
    transform: translateY(-2px);
  }

  

  /* For wide computer screen*/
@media only screen and (min-width: 770px) {

  a.linkedin {
    color: #0077b5 !important;
  }
  
  a.facebook {
    color: #4968ad;
  }
  
  a.github {
    color: #171515;
  }

  .mailSocial {
    color: #171515;
    margin: 0 1rem;
    transition: transform 250ms;
    display: inline-block;
  }

}

 /* For mobile screen*/
 @media only screen and (max-width: 770px) {

  a.linkedin {
    color: #0077b5 !important;
    font-size: 13px;
  }
  
  a.facebook {
    color: #4968ad;
    font-size: 13px;

  }
  
  a.github {
    color: #171515;
    font-size: 13px;

  }

  .mailSocial {
    color: #171515;
    margin: 0 1rem;
    transition: transform 250ms;
    display: inline-block;
    font-size: 38px;

  }

}
  