

.headlineE {
    color: #171515;
    font-family: 'Noto Sans Mono', monospace;
}

.timeLine {
    width: 500px;
    height: 950px;

}

.chrono {
    height: 500px;
    width: 500px;
}

.separator{
    display: flex;
    align-items: center !important;
    margin-bottom: 40px;
  }

.separator h1{
    padding: 0 1rem; /* creates the space */
  }

.separator .line1{
    flex: 1;
    height: 1px;
    background-color: #000;
  }

  .separator .line2{
    flex: 10;
    height: 1px;
    background-color: #000;
  }

